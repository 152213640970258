.scrollTop {
  position: fixed;
  width: 40px;
  bottom: 20px;
  align-items: center;
  height: 40px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  border: 1px solid;
  border-radius: 50%;
  bottom: 15px;
  right: 15px;
}

.scrollTop:hover {
  opacity: 1;
  width: 80px;
  height: 80px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
